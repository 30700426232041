import { makeStyles, Paper, Box } from "@material-ui/core";
import classNames from "classnames";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Theme } from "../../theme/types/Theme";
import { getCommissionAgreement, sendEmail } from "../../tools";
import { DataType, SignatureType, WithClassName } from "../../types";
import { DialogBoxFooter } from "./DialogBoxFooter";
import { DialogBoxTerms } from "./DialogBoxTerms";
import { DialogBoxText } from "./DialogBoxText";

const ERROR_MESSAGE_EMAIL_SERVICE_NOT_AVAILABLE =
    "Dieser Service ist derzeit nicht verfügbar. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
const EMAIL_STATUS_CODE_OK = 202;

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(3),
            },
            display: "grid",
            gridGap: theme.spacing(3),
            gridTemplateAreas: `
                'content'
                'terms'
                'footer'
            `,
            gridTemplateRows: "1fr min-content min-content",
        },
        terms: {
            gridArea: "terms",
        },
        footer: {
            gridArea: "footer",
        },
    }),
    { name: "DialogBox" }
);

export interface DialogBoxProps extends WithClassName<typeof useStyles> {
    data: DataType;
    signature: SignatureType;
    checkboxes?: string[];
}

export interface CheckedTermsType {
    [key: number]: boolean;
}

export const DialogBox = (props: DialogBoxProps) => {
    const {
        className,
        classes: classesProp,
        data,
        signature,
        checkboxes,
    } = props;
    const classes = useStyles({ ...props, classes: classesProp });
    const [valid, setValid] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [checkedTerms, setCheckedTerms] = useState<CheckedTermsType>({});
    const { t } = useTranslation();

    const showCheckboxFromBackend = useMemo(
        () => checkboxes && checkboxes.length > 0 && checkboxes[0],
        [checkboxes]
    );

    const ref = useRef<HTMLDivElement>(null);
    const url = data.callbackUrl;

    useEffect(() => {
        const newCheckingState: CheckedTermsType = {};
        if (checkboxes) {
            checkboxes.forEach((checkbox, index) => {
                newCheckingState[index] = false;
            });
            setCheckedTerms(newCheckingState);
        } else {
            setCheckedTerms({ 0: false });
        }
    }, [checkboxes]);

    useEffect(() => {
        setValid(Object.values(checkedTerms).every(Boolean));
    }, [checkedTerms]);

    const handleChange = useCallback(
        (index) =>
            (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setCheckedTerms((terms) => ({ ...terms, [index]: checked }));
            },
        []
    );

    const emailBody = useMemo(() => {
        if (data) {
            const commissionAgreement = getCommissionAgreement(
                ref.current,
                !data.id
            );
            if (data.id) {
                return {
                    commissionAgreementInPlatformApps: commissionAgreement,
                    commissionConfirmationId: data.id,
                };
            }
            return {
                signature,
                commissionAgreement,
                agentEmail: data.agentEmail,
                email: data.email,
                salutation: data.salutation,
            };
        }
    }, [data, ref.current, signature]);

    const handleNext = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        setError(false);
        setErrorMessage(undefined);
        try {
            if (!emailBody) {
                return;
            }
            const response = await sendEmail(
                emailBody,
                !emailBody.commissionConfirmationId
            );
            if (!response || response.status !== EMAIL_STATUS_CODE_OK) {
                throw new Error("Response status code invalid");
            }
            window.open(url, "_self");
        } catch (e) {
            setError(true);
            setErrorMessage(ERROR_MESSAGE_EMAIL_SERVICE_NOT_AVAILABLE);
        }

        setLoading(false);
    }, [url, signature, isLoading, ref.current]);

    if (Object.keys(checkedTerms).length === 0) {
        return null;
    }

    return (
        <Paper className={classNames(className, classes.root)}>
            <DialogBoxText
                company={signature.company}
                data={data}
                ref={ref}
                isLegacy={!emailBody?.commissionConfirmationId}
            />
            <Box className={classes.terms}>
                {showCheckboxFromBackend ? (
                    checkboxes?.map((checkbox, index) => (
                        <DialogBoxTerms
                            key={index}
                            onChange={handleChange(index)}
                            label={parse(checkbox)}
                            termChecked={checkedTerms?.[index]}
                        />
                    ))
                ) : (
                    <DialogBoxTerms
                        onChange={handleChange(0)}
                        label={t("dialogBoxTerms.term.label")}
                        termChecked={checkedTerms?.[0]}
                    />
                )}
            </Box>
            <DialogBoxFooter
                valid={valid}
                onNextClicked={handleNext}
                isLoading={isLoading}
                isError={isError}
                errorMessage={errorMessage}
                classes={{ root: classes.footer }}
            />
        </Paper>
    );
};
