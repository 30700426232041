import { Location } from "history";
import qs from "qs";
import {
    CommissionDataType,
    DataType,
    EmailDataType,
    EmailDataTypeLegacy,
    SignatureType,
} from "../types";
import { b64Decode, b64Encode } from "./EncodingHelper";

const platformUrl =
    process.env.NODE_ENV === "development"
        ? "https://api.development.cloudios.flowfact-dev.cloud"
        : "https://api.production.cloudios.flowfact-prod.cloud";
const authorizationHeader =
    "Basic MDQyYTU4NzUtYTM3NS00MWU5LTlkOGMtNTQxNTRkMzNhNGRmOjM1NjQxNGExLTM5MjAtNDZlOC1hNWMxLWQ2MzJiYjliOGE3OQ==";

export const getParams = async (
    location: Location<unknown>
): Promise<CommissionDataType | null> => {
    const { data } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder: (c) => c,
    });
    if (!data) {
        return getParamsNew(location);
    }
    return getParamsLegacy(location);
};

export const getParamsLegacy = async (
    location: Location<unknown>
): Promise<CommissionDataType> => {
    const { data: encodedData, signature: encodedSignature } = qs.parse(
        location.search,
        {
            ignoreQueryPrefix: true,
            decoder: (c) => c,
        }
    );
    return {
        confirmation: JSON.parse(b64Decode(encodedData as string)) as DataType,
        signature: JSON.parse(
            b64Decode(encodedSignature as string)
        ) as SignatureType,
    };
};

export const getParamsNew = async (
    location: Location<unknown>
): Promise<CommissionDataType | null> => {
    const { id, aktDsn } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        decoder: (c) => c,
    });
    const data = await fetch(
        `${platformUrl}/legacy-crm-sexposee-service/public/commission-confirmations/?${qs.stringify(
            { id, aktDsn }
        )}`,
        {
            method: "GET",
            headers: {
                Authorization: authorizationHeader,
            },
        }
    ).then(async (response) => await response.json());
    if (!data) {
        return null;
    }

    return data.commissionConfirmations?.[0];
};

export const sendEmail = (
    data: EmailDataType | EmailDataTypeLegacy,
    isLegacy: boolean
): Promise<Response> => {
    const emailUrl = isLegacy
        ? `${platformUrl}/email-service/public/legacy/commission-split/confirmation`
        : `${platformUrl}/legacy-crm-sexposee-service/public/commission-confirmation-mailings`;
    return fetch(emailUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: authorizationHeader,
        },
        body: JSON.stringify(data),
    });
};

export const getCommissionAgreement = (
    el: HTMLElement | null,
    isLegacy: boolean
): string => {
    if (el === null) {
        return "";
    }
    // here we can transform text
    // add some styling or remove sth unnecessary

    const tmpWrapper = document.createElement("div");
    tmpWrapper.innerHTML = el.innerHTML;

    tmpWrapper.querySelectorAll(".hideInEmail").forEach((el) => el.remove());
    tmpWrapper.querySelectorAll("[class]").forEach((el) => {
        el.removeAttribute("class");
    });

    return isLegacy ? tmpWrapper.innerHTML : b64Encode(tmpWrapper.innerHTML);
};
