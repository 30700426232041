import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { Theme } from "../../theme/types/Theme";
import { WithClassName } from "../../types";

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        group: {
            display: "grid",
            gridTemplateColumns: "1fr min-content",
            gridGap: theme.spacing(1),
        },
    }),
    { name: "DialogBoxTerms" }
);

export interface DialogBoxTermsProps extends WithClassName<typeof useStyles> {
    termChecked: boolean;
    onChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    label: ReactNode;
}

export const DialogBoxTerms = (props: DialogBoxTermsProps) => {
    const {
        className,
        classes: classesProp,
        onChange,
        termChecked,
        label,
    } = props;
    const classes = useStyles({ ...props, classes: classesProp });

    return (
        <Box className={classNames(classes.root, className)}>
            <FormGroup className={classes.group}>
                <FormControlLabel
                    key={"term-1"}
                    label={<>{label} (*)</>}
                    control={
                        <Checkbox
                            checked={termChecked}
                            color="primary"
                            onChange={onChange}
                            name={"term-1"}
                        />
                    }
                />
            </FormGroup>
        </Box>
    );
};
