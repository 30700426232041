import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {EstateType} from '../../types';
import {text} from './translation';

interface CommissionTextProps {
    estate: EstateType;
    companyName: string
}

export const CommissionText = (props: CommissionTextProps) => {
    const {estate, companyName} = props;
    const {commission, identifier, includesTaxes, tradeType, commissionInfo} = estate;
    const {t} = useTranslation();
    const hasCommission = commission !== undefined && commission !== '' && commission !== ',00€' && commission !== ',00 €';

    const provisionType = hasCommission ? 'commissionText.type.provision' : 'commissionText.type.free';
    const includeTaxesText = (includesTaxes === undefined) ? '' : includesTaxes ? ' ' + text.commissionText.tax.includes : ' ' + text.commissionText.tax.excludes;

    return (
        <>
            <Trans i18nKey={'commissionText.provisionInfo'} values={{identifier, provisionType: t(provisionType)}}>
                The offered property under the <strong> property number {{identifier}} </strong>
                is {{provisionType}}.
            </Trans>
            {' '}
            {
                commissionInfo && commissionInfo !== ''
                    ?
                    tradeType === 'RENT' ? (
                        <>
                            Mit dem Anklicken der Checkbox bestätige ich den Provisionshinweis des
                            Maklers <strong>{companyName}</strong> und bezahle für den Fall, dass ich diese Immobilie
                            mieten werde die entsprechende Provision, verdient und fällig bei Unterzeichnung des Mietvertrages.
                            <br /><strong>{commissionInfo}</strong>
                        </>
                    ) : (
                        <>
                            Mit dem Anklicken der Checkbox bestätige ich den Provisionshinweis des
                            Maklers <strong>{companyName}</strong> und bezahle für den Fall, dass ich diese Immobilie
                            erwerben werde die entsprechende Provision, verdient und fällig bei notarieller Beurkundung.
                            <br /><strong>{commissionInfo}</strong>
                        </>
                    )
                    :
                    hasCommission && (<>
                        {tradeType === 'RENT' ? (
                            <Trans i18nKey={'commissionText.commissionInfo_rent'}
                                   values={{companyName, commission}}
                            >
                                By clicking the checkbox I confirm the commission notice from the
                                broker <strong> {{companyName}} </strong> and, in the event that I will rent this property,
                                I will pay a commission of <strong>{{commission}} commission</strong>, earned and due upon
                                signing the Rental agreement.
                            </Trans>
                        ) : (<>
                                Mit dem Anklicken der Checkbox bestätige ich den Provisionshinweis des
                                Maklers <strong>{companyName}</strong> und bezahle für den Fall, dass ich diese Immobilie
                                erwerben werde eine Provision in Höhe von <strong>{commission} Käufer-Provision
                                {includeTaxesText}</strong>, verdient und fällig bei notarieller Beurkundung.
                            </>
                        )}
                    </>)}
        </>
    );
};
