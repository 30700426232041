import { Box, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../theme/types/Theme";
import { b64Decode } from "../../tools/EncodingHelper";
import { DataType, WithClassName } from "../../types";
import { CommissionText } from "./CommissionText";
import { text } from "./translation";
import parse from "html-react-parser";

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
        },
        bold: {
            fontWeight: "bold",
        },
        contentWrap: {
            backgroundColor: theme.features.dialogBoxText.backgroundColor,
            position: "relative",
            height: "100%",
            overflowY: "auto",
            minHeight: "30vh",
        },
        content: {
            position: "absolute",
            top: 0,
            left: 0,
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(3),
            },
        },
    }),
    { name: "DialogBoxText" }
);

export interface DialogBoxTextProps extends WithClassName<typeof useStyles> {
    data: DataType;
    company: string;
    isLegacy?: boolean;
}

export const DialogBoxText = forwardRef<HTMLDivElement, DialogBoxTextProps>(
    (props, ref) => {
        const {
            className,
            classes: classesProp,
            data,
            company,
            isLegacy,
        } = props;
        const classes = useStyles({ ...props, classes: classesProp });
        const { t } = useTranslation();

        const allAreTradeTypeRent = data.estates.reduce(
            (previousAreAllTradeTypeRent, estate) =>
                previousAreAllTradeTypeRent && estate.tradeType === "RENT",
            true
        );
        const paragraphsTranslationKey = allAreTradeTypeRent
            ? text.dialogBoxText.termsOfUse.textRent
            : text.dialogBoxText.termsOfUse.textPurchase;

        const paragraphs = paragraphsTranslationKey
            .split("\n")
            .map((paragraph) => paragraph.trim())
            .filter((paragraph) => paragraph.length > 0);

        return (
            <Box className={classNames(classes.root, className)}>
                {isLegacy && (
                    <Typography variant="h6" className={classes.bold}>
                        {t("dialogBoxText.termsOfUse.label")}
                    </Typography>
                )}
                <div className={classes.contentWrap}>
                    <div className={classes.content} ref={ref}>
                        {isLegacy && (
                            <>
                                <Box mb={2}>
                                    {paragraphs.map((paragraph, key) => (
                                        <Typography
                                            key={`terms_of_use__text_paragraph_${key}`}
                                        >
                                            {paragraph}
                                        </Typography>
                                    ))}
                                    <Typography className="hideInEmail">
                                        {t(
                                            "dialogBoxText.termsOfUse.textContinuation"
                                        )}
                                    </Typography>
                                </Box>

                                <Typography className={classes.bold} paragraph>
                                    {t(
                                        "dialogBoxText.confirmationOfCommissionAgreement"
                                    )}
                                </Typography>

                                <ul>
                                    {data.estates.map((estate) => (
                                        <li
                                            key={`terms_of_use__provision_for_${estate.identifier}`}
                                        >
                                            <Typography paragraph>
                                                <CommissionText
                                                    estate={estate}
                                                    companyName={company}
                                                />
                                            </Typography>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {!isLegacy &&
                            data.commissionAgreement &&
                            parse(b64Decode(data.commissionAgreement))}
                        {!isLegacy &&
                            data.cancellationPolicy &&
                            parse(b64Decode(data.cancellationPolicy))}
                    </div>
                </div>
            </Box>
        );
    }
);
