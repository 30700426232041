export const b64DecodeUnicode = (str: string): string => {
    const decodedStr = Array.prototype.map
        .call(atob(str), function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("");
    return decodeURIComponent(decodedStr);
};

export const b64Decode = (str: string): string => {
    try {
        return b64DecodeUnicode(str);
    } catch (e) {
        // May be problem with decoding of utf-8 change to normal decoding
        return atob(str);
    }
};

export const b64Encode = (str: string): string => {
    try {
        return btoa(unescape(encodeURIComponent(str)));
    } catch (e) {
        return btoa(str);
    }
};

export const isBase64 = (str: string) => {
    if (str === "" || str.trim() === "") {
        return false;
    }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
};
