import { makeStyles, Paper, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { Theme } from "../theme/types/Theme";

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            padding: theme.spacing(1),
            maxHeight: "fit-content",
            display: "flex",
        },
        bar: {
            backgroundColor: ({ type = "info" }: InfoBoxProps) =>
                theme.palette[type].main,
            width: theme.spacing(1) / 2,
            borderRadius: 2,
        },
        icon: {
            padding: theme.spacing(1, 0, 1, 1),
            color: ({ type = "info" }: InfoBoxProps) =>
                theme.palette[type].main,
        },
        message: {
            padding: theme.spacing(1),
        },
    }),
    { name: "InfoBox" }
);

export interface InfoBoxProps {
    className?: string;
    message: string;
    type?: "info" | "error";
}

export const InfoBox = (props: InfoBoxProps) => {
    const { className, message } = props;
    const classes = useStyles(props);

    return (
        <Paper className={classNames(classes.root, className)}>
            <span className={classes.bar} />
            <InfoOutlined className={classes.icon} />
            <Typography className={classes.message}>{message}</Typography>
        </Paper>
    );
};
