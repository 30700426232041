import {Box, Button, CircularProgress, Collapse, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../theme/types/Theme';
import { WithClassName } from '../../types';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            display: 'flex',
            gridGap: theme.spacing(2),
            alignItems: 'start',
        },
        text: {
            flexGrow: 1,
            whiteSpace: 'nowrap',
        },
        progress: {},
        error: {
            color: theme.palette.error.main,
        },
        button: {
            whiteSpace: 'nowrap',
            textTransform: 'unset',
            flexShrink: 1,
        },
    }), { name: 'DialogBoxFooter' }
);

interface DialogBoxFooterProps extends WithClassName<typeof useStyles> {
    valid: boolean;
    onNextClicked: () => void;
    isLoading: boolean;
    isError: boolean;
    errorMessage?:string;
}

export const DialogBoxFooter = (props: DialogBoxFooterProps) => {
    const { valid, onNextClicked, isLoading, isError, errorMessage } = props;
    const classes = useStyles(props);
    const { t } = useTranslation();

    return (
        <Box >
            <Box className={classes.root}>
                <Typography className={classes.text} variant='body1'>{t('dialogBoxFooter.required')}</Typography>

                <Box display={{xs: 'none', sm: 'flex'}} alignItems='center'>
                    {isLoading && <CircularProgress className={classes.progress} size={24} />}
                    <Button className={classes.button} variant='contained' color='primary' disabled={!valid || isLoading}
                            onClick={onNextClicked}>{t('dialogBoxFooter.button.accept')}</Button>
                </Box>

            </Box>
            <Box display={{xs: 'flex', sm: 'none'}} alignItems='center' mt={1} mb={1}>
                <Button className={classes.button} variant='contained' color='primary' disabled={!valid || isLoading}
                        onClick={onNextClicked}>{t('dialogBoxFooter.button.accept')}</Button>
                {isLoading && <CircularProgress className={classes.progress} size={24} />}
            </Box>
            <Box mt={1}>
                <Collapse in={isError}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Collapse>
            </Box>
        </Box>
    );
};
