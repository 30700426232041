import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        load: 'languageOnly',
        initImmediate: false,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
        },
        supportedLngs: ['de'],
    });

export default i18n;
