import { Container, makeStyles, Theme } from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { getParams } from "../../tools";
import { b64Decode, isBase64 } from "../../tools/EncodingHelper";
import { CommissionDataType } from "../../types";
import { DialogBox } from "../DialogBox/DialogBox";
import { InfoBox } from "../InfoBox";

import Image from "./overlayBG_email.png";

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            display: "grid",
            backgroundColor: theme.palette.background.default,
            height: "100%",
            overflow: "auto",
            background: `url(${Image}) no-repeat center center fixed`,
            backgroundSize: "cover",
        },
        container: {
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(0),
                gridGap: theme.spacing(1),
            },
            display: "grid",
            gridTemplateRows: "min-content 1fr",
            padding: theme.spacing(3, 0),
            gridGap: theme.spacing(3),
        },
        dialogBox: {
            overflow: "scroll",
        },
    }),
    { name: "App" }
);

export const App = () => {
    const classes = useStyles();
    const location = useLocation();
    const { t } = useTranslation();
    const [commissionData, setCommissionData] =
        useState<CommissionDataType | null>(null);

    useEffect(() => {
        async function fetchData() {
            const data = await getParams(location);
            setCommissionData(data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const noEstatesWithCommissions =
            commissionData?.confirmation?.estates?.filter(
                (estate) => estate.commission === undefined
            ).length === commissionData?.confirmation.estates.length;
        if (noEstatesWithCommissions) {
            window.open(commissionData?.confirmation.callbackUrl, "_self");
        }
    }, [commissionData]);

    const isLegacy = useMemo(() => !commissionData?.id, [commissionData]);

    const hasCustomPolicies = useMemo(
        () =>
            commissionData?.cancellationPolicy &&
            commissionData?.commissionAgreement,
        [
            commissionData?.cancellationPolicy,
            commissionData?.commissionAgreement,
        ]
    );

    return (
        <div className={classes.root}>
            <Container maxWidth="md" className={classes.container}>
                {!commissionData || (!isLegacy && !hasCustomPolicies) ? (
                    <InfoBox message={t("infoBox.error")} type="error" />
                ) : (
                    <InfoBox message={t("infoBox.message")} />
                )}

                {commissionData &&
                    (isLegacy || (!isLegacy && hasCustomPolicies)) && (
                        <DialogBox
                            className={classes.dialogBox}
                            data={{
                                ...commissionData.confirmation,
                                id: commissionData.id,
                                cancellationPolicy:
                                    commissionData.cancellationPolicy,
                                commissionAgreement:
                                    commissionData.commissionAgreement,
                            }}
                            signature={commissionData.signature}
                            checkboxes={commissionData.mandatoryAssents?.map(
                                (checkbox) =>
                                    isBase64(checkbox)
                                        ? b64Decode(checkbox)
                                        : checkbox
                            )}
                        />
                    )}
            </Container>
        </div>
    );
};
