import { ThemeProvider } from "@material-ui/core";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { App } from "./components/App/App";
import { NotFoundPage } from "./components/NotFoundPage/NotFoundPage";
import "./i18n";
import "./index.css";
import { theme } from "./theme";

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/confirmation">
                            <App />
                        </Route>
                        <Route>
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById("application")
);
