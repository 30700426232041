import { Container, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../../theme/types/Theme';
import { WithClassName } from '../../types';
import Image from './404-background-img-1.jpg';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            display: 'grid',
            height: '100vh',
            backgroundColor: theme.palette.primary.main
        },
        container: {
            padding: theme.spacing(3, 0),
        },
        content: {
            padding: theme.spacing(3),
            '& > img': {
                maxWidth: '100%',
            }
        },
        title: {
            color: theme.features.notFoundPage.titleColor,
        },
    }), { name: 'NotFoundPage' }
);

interface NotFoundPageProps extends WithClassName<typeof useStyles> {

}

export const NotFoundPage = (props: NotFoundPageProps) => {
    const { classes: classesProp, className } = props;
    const classes = useStyles({ ...props, classes: classesProp });
    return (
        <div className={classNames(classes.root, className)}>
            <Container maxWidth='md' className={classes.container}>
                <Paper className={classes.content}>
                    <Typography variant='h1' className={classes.title} align='center' paragraph>Page not
                        found</Typography>
                    <Divider />
                    <img src={Image} alt="404" />
                </Paper>
            </Container>
        </div>
    );
};
