import { createMuiTheme } from '@material-ui/core';
import { grey, teal } from '@material-ui/core/colors';
import merge from 'deepmerge';
import { DeepPartial } from '../types';
import { Theme } from './types/Theme';

const defaultTheme = createMuiTheme();
export const theme: Theme = merge<Theme, DeepPartial<Theme>>(defaultTheme, {
    palette: {
        type: 'light',
        primary: {
            main: '#00ccc0',
            dark: teal['500'],
            contrastText: '#ffffff',
        },
        background: {
            default: grey['100'],
        }
    },
    features: {
        dialogBoxText: {
            backgroundColor: '#e7f0f5',
        },
        notFoundPage: {
            titleColor: '#c0d2dd',
        }
    }
});
