export const text = {
    infoBox: {
        message:
            "Um den Link aus der E-Mail zu öffnen, müssen Sie den nachstehenden Bedingungen zustimmen.",
        error: "Das Dokument enthält keine Rechtstexte!",
    },
    dialogBox: {},
    dialogBoxFooter: {
        button: {
            accept: "Ich akzeptiere",
        },
        required: "(*) Pflichtfeld",
    },
    dialogBoxTerms: {
        term: {
            label: "Ich bestätige, die Provisionsbedingungen gelesen zu haben, und erkläre mich mit deren Inhalt einverstanden.",
        },
    },
    dialogBoxText: {
        confirmationOfCommissionAgreement: "Bestätigung Provisionsvereinbarung",
        termsOfUse: {
            label: "Provisionsbedingungen",
            textPurchase:
                "Dieses Angebot enthält provisionspflichtige Immobilien. Die Provision wird vom Käufer getragen und ist verdient und zahlbar an den Nachweis-/Vermittlungsmakler bei Kaufvertragsabschluss. Eine Provision wird nur fällig, wenn es zu einem Nachweis oder einer Vermittlung einer Gelegenheit zum Abschluss eines Hauptvertrages kommt.",
            textRent:
                "Dieses Angebot enthält provisionspflichtige Immobilien. Die Provision wird vom Mieter getragen und ist verdient und zahlbar an den Nachweis-/Vermittlungsmakler bei Mietvertragsabschluss. Eine Provision wird nur fällig, wenn es zu einem Nachweis oder einer Vermittlung einer Gelegenheit zum Abschluss eines Mietvertrages kommt.",
            textContinuation:
                "Nach Bestätigung erhalten Sie selbstverständlich die hier gemachten Angaben für Ihre Unterlagen per E-Mail.",
        },
    },
    commissionText: {
        type: {
            provision: "provisionspflichtig",
            free: "provisionsfrei",
        },
        provisionInfo:
            "Die angebotene Immobilie unter der <1>Objektnummer {{ identifier }}</1> ist {{ provisionType }}.",
        commissionInfo:
            "Mit dem Anklicken der Checkbox bestätige ich den Provisionshinweis des Maklers <1>{{ companyName }}</1> und bezahle für den Fall, dass ich diese Immobilie erwerben werde eine Provision in Höhe von <3>{{ commission }} Käufer-Provision {{ includeTaxesText }}</3>, verdient und fällig bei notarieller Beurkundung.",
        commissionInfo_rent:
            "Mit dem Anklicken der Checkbox bestätige ich den Provisionshinweis des Maklers <1>{{ companyName }}</1> und bezahle für den Fall, dass ich diese Immobilie mieten werde eine Provision in Höhe von <3>{{ commission }} Provision</3>, verdient und fällig bei Unterzeichnung des Mietvertrages.",
        tax: {
            includes: "inkl. MwSt.",
            excludes: "exkl. MwSt.",
        },
    },
};
